import React, { useState, useEffect, Fragment, useContext } from "react"
import { Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import SalesImg from "../../Assets/Images/Dashboard/sales.png"
import ExpensesImg from "../../Assets/Images/Dashboard/expenses.png"
import ReceivablesImg from "../../Assets/Images/Dashboard/receivables.png"
import NetsalesImg from "../../Assets/Images/Dashboard/netsales.png"
import moment from "moment"
import Navbar from "../../Components/Navbar/Navbar"
import { Context } from "../../Helpers/Context/Context"

import "./Dashboard.css"
import {
  formatDateNoTime,
  getName,
  getTime,
  getType,
  formatAmount,
  TokenExpiry,
  getCurrentMonthRange,
} from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { DatePicker } from "antd"
import dayjs from "dayjs"

export default function Dashboard3() {
  const { RangePicker } = DatePicker
  const dateRange = getCurrentMonthRange()
  const [filterConfig, setFilterConfig] = useState({
    date_from: dateRange.date_from,
    date_to: dateRange.date_to,
  })

  console.log(filterConfig)
  let navigate = useNavigate()
  const type = getType()
  const { selectedBuId } = useContext(Context)
  const [data, setData] = useState({
    sales: 0,
    expenses: 0,
    net_sales: 0,
    receivables: 0,
    pending_invoice: 0,
    pending_expense: 0,
  })

  const [inactive, setInactive] = useState(false)
  const days = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  }

  // Dashboard Data
  async function fetchData() {
    const response = await UseGetRequest("reports/get_dashboard_reports", {
      business_unit_id: selectedBuId,
      date_from: dayjs(filterConfig.date_from).format("YYYY-MM-DD"),
      date_to: dayjs(filterConfig.date_to).format("YYYY-MM-DD"),
    })

    if (response.data) {
      setData(response.data.data)
    } else if (response.error) {
      TokenExpiry(response)
    }
  }

  useEffect(() => {
    fetchData()
    // document.documentElement.style.setProperty(
    //   "--secondary-color",
    //   primaryColor
    // )
    // document.documentElement.style.setProperty("--primary-color", primaryColor)
    // fetchOpenBranches();
  }, [filterConfig])

  return (
    <div className="dashboard-wrapper justify-content-evenly">
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"DASHBOARD"} //Dashboard navbar index
        />
      </div>
      <div className={`dashboard ${inactive ? "inactive" : "active"}`}>
        <div className={"dashboard-wrapper"}>
          <Row className="d-flex justify-content-between align-items-end">
            <Col xs={"auto"}>
              <h2 className="font-medium">
                Hello, <b>{getName()}!</b>👋
              </h2>
            </Col>
            <Col xs={"auto"}>
              <h3 className="date-and-time">{`${getTime(new Date())} ${
                days[new Date().getDay()]
              } | ${formatDateNoTime(new Date())}`}</h3>
            </Col>
          </Row>
          {type === "admin" ||
          type === "business_unit_head" ||
          type === "business_unit_director" ||
          type === "finance_1" ||
          type === "general_manager" ||
          type === "business_unit_manager" ||
          type === "finance_2" ||
          type === "requester" ? (
            <Fragment>
              <Row className="d-flex">
                <Col xs={24} className="mt-5 mb-3">
                  <h4>Date Range</h4>
                  <RangePicker
                    defaultValue={[
                      dayjs(filterConfig.date_from, "YYYY-MM-DD"),
                      dayjs(filterConfig.date_to, "YYYY-MM-DD"),
                    ]}
                    onChange={(value) => {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: dayjs(value[0], "YYYY-MM-DD"),
                        date_to: dayjs(value[1], "YYYY-MM-DD"),
                      }))
                    }}
                  />
                </Col>
                <Col>
                  <Row>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 cursor">
                      <div className="me-2 justify-content-center">
                        <img
                          src={SalesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div
                          className="big-hdr"
                          onClick={(e) => navigate("/projectsales")}
                        >
                          Sales
                        </div>
                        <div
                          className="stats"
                          onClick={(e) => navigate("/projectsales")}
                        >
                          PHP{formatAmount(data?.sales ?? 0)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 cursor">
                      <div className="me-2">
                        <img
                          src={ExpensesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row className="justify-content">
                        <div
                          className="big-hdr"
                          onClick={(e) =>
                            navigate(
                              "/expensereport/" +
                                moment().format("YYYY-MM-DD") +
                                "/" +
                                moment().format("YYYY-MM-DD")
                            )
                          }
                        >
                          Expenses
                        </div>
                        <div
                          className="stats"
                          onClick={(e) =>
                            navigate(
                              "/expensereport/" +
                                moment().format("YYYY-MM-DD") +
                                "/" +
                                moment().format("YYYY-MM-DD")
                            )
                          }
                        >
                          PHP{formatAmount(data.expenses)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 cursor">
                      <div className="me-2">
                        <img
                          src={ReceivablesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div
                          className="big-hdr"
                          onClick={(e) => navigate("/receivablesagingreport")}
                        >
                          Receivables
                        </div>
                        <div
                          className="stats"
                          onClick={(e) => navigate("/receivablesagingreport")}
                        >
                          PHP{formatAmount(data.receivables)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2">
                      <div className="me-2">
                        <img
                          src={NetsalesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div
                          className="big-hdr"
                          // onClick={(e) => navigate("/netsales")}
                        >
                          Net Sales
                        </div>
                        <div
                          className="stats"
                          // onClick={(e) => navigate("/netsales")}
                        >
                          PHP
                          {formatAmount(data.net_sales ? data.net_sales : 0.0)}
                        </div>
                        <div className="box-low-text">for the month</div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col className="mt-3">
                  <Row>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/projectpurchaseinvoice")}
                        >
                          Pending Invoice
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/projectpurchaseinvoice")}
                        >
                          {data.pending_invoice}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header text-left cursor"
                          onClick={(e) =>
                            navigate("/projectpurchaseinvoice", {
                              state: { name: "sent" },
                            })
                          }
                        >
                          Open Billing
                        </div>
                        <div
                          className="box-text text-center cursor"
                          onClick={(e) =>
                            navigate("/projectpurchaseinvoice", {
                              state: { name: "sent" },
                            })
                          }
                        >
                          0{data.open_billing}
                        </div>
                        <div className="box-low-text text-end">
                          {" "}
                          Total Number
                        </div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/se/purchaseinvoices")}
                        >
                          Open Supplies Expense
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/se/purchaseinvoices")}
                        >
                          0{data.open_suppliesexpense}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col className="mt-3">
                  <Row>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/projectexpense")}
                        >
                          Pending Project Expense
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/projectexpense")}
                        >
                          {data.pending_expense}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/pettycash")}
                        >
                          Petty Cash
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/pettycash")}
                        >
                          0{data.petty_cash}
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                    <Col className="box-1 mx-2">
                      <div className="me-2"></div>
                      <Row>
                        <div
                          className="header cursor"
                          onClick={(e) => navigate("/opexpurchaseinvoice")}
                        >
                          Pending POs
                        </div>
                        <div
                          className="box-text cursor"
                          onClick={(e) => navigate("/opexpurchaseinvoice")}
                        >
                          0
                        </div>
                        <div className="box-low-text"> Total Number</div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          ) : (
            /* Content for non-admin users */
            <div>Content for non-admin users</div>
          )}
        </div>
      </div>
    </div>
  )
}
