import moment from "moment"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import cleanLogo from "../../Assets/Images/Login/logo.png"
import Navbar from "../../Components/Navbar/Navbar"
import {
  isAdmin,
  formatAmount,
  refreshPage,
  toastStyle,
  TokenExpiry,
} from "../../Helpers/Utils/Common"
import "./ProjectInvoice.css"
import { getSingleBillingSI } from "../../Helpers/apiCalls/BillingApi"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Checkbox, Row, Col } from "antd"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"

export default function PrintBillingSalesInvoice() {
  const { id } = useParams() // id
  let navigate = useNavigate()

  const [inactive, setInactive] = useState(true)
  const [billingDetails, setBillingDetails] = useState([])
  const [items, setItems] = useState([])
  const [businessInfo, setBusinessInfo] = useState({})
  const [callChangeStatusApi, setCallChangeStatusApi] = useState(false)

  async function fetchBusinessInfo(projectId) {
    const response = await UseGetRequest("distributors/get_all_distributor", {
      project_id: projectId,
    })
    if (response.data) {
      setBusinessInfo(response.data.data[0])
    }
  }

  async function fetchProjectInvoice() {
    setBillingDetails({})
    setItems([])

    const response = await getSingleBillingSI(id)

    if (response.error) {
      TokenExpiry(response)
    } else {
      var tempData = response.data.data[0]
      fetchBusinessInfo(tempData.project_id)
      const tempTwelve = parseFloat(tempData.sub_total) * 0.12
      const tempGross =
        parseFloat(tempData.sub_total ?? 0) - parseFloat(tempData.discount ?? 0)
      const tempVatIn = tempGross + tempTwelve
      const tempWhtAmount =
        parseFloat(tempGross) * (parseFloat(tempData.wht_percent ?? 0) / 100)
      const tempNet = tempVatIn - tempTwelve
      const tempAmountDue = tempNet - tempWhtAmount

      tempData.vat_in = tempVatIn
      // tempData.amount_due =
      tempData.amount_due =
        parseFloat(tempData.net_vat || 0) - parseFloat(tempData.wht_amount || 0)
      // tempData.grand_total =
      //   tempData.vat_type === "vat_in"
      //     ? tempAmountDue + parseFloat(tempData.wht_percent ?? 0)
      //     : tempGross
      setBillingDetails(tempData)
      setItems(tempData.billing_sales_invoice_items)
    }
  }

  async function handlePrint() {
    toast.loading("Printing project invoice...", { style: toastStyle() })
    setTimeout(() => {
      toast.dismiss()
      changeStatus()
      Print()
    }, 1000)
  }

  function Print() {
    let printContents = document.getElementById("printablediv").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print(printContents)
    document.body.innerHTML = originalContents
  }

  async function changeStatus() {
    const response = await UsePostRequest(
      "billing_sales_invoices/change_status",
      {
        new_status: "print",
        billing_sales_invoice_id: id,
      }
    )

    if (response?.data) {
      refreshPage()
    } else {
      refreshPage()
    }
  }

  useEffect(() => {
    if (callChangeStatusApi) {
      changeStatus()
    }
  }, [])

  React.useEffect(() => {
    fetchProjectInvoice()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PROJECT EXPENSES"}
        />
      </div>
      <div
        className={`manager-container p-5 ${inactive ? "inactive" : "active"}`}
      >
        <div className="print-container" id="printablediv">
          <div className="m-5 print-pdf-container">
            <Row>
              <Col
                xs={4}
                className="d-flex justify-content-center print-logo-container p-0"
              >
                <img
                  src={
                    businessInfo?.distributor_attachment &&
                    businessInfo?.distributor_attachment[0].base_64
                  }
                  alt={cleanLogo}
                  className="w-100"
                />
              </Col>
              <Col xs={20} className="mt-2">
                <Row className="d-flex justify-content-end">
                  <h1>{businessInfo.name}</h1>
                </Row>
                <Row className="d-flex justify-content-end">
                  {businessInfo.address}
                </Row>
                <Row className="d-flex justify-content-end">
                  VAT Reg. TIN: {businessInfo.tin}
                </Row>
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              <Col xs={18}>
                <h3>SERVICE INVOICE</h3>
              </Col>
              <Col xs={6}>
                <h3>{billingDetails.invoice_no}</h3>
              </Col>
            </Row>
            <Row>
              <Checkbox>CASH</Checkbox>
              <Checkbox>CHARGE</Checkbox>
            </Row>
            <Row className="mt-2 align-items-center">
              <Col xs={18} className="">
                Billed to:{" "}
                <span className="ms-2">{billingDetails.company}</span>
              </Col>
              <Col xs={6} className="">
                Date:{" "}
                <span className="ms-2">
                  {moment(billingDetails.invoice_date).format("MMMM DD, YYYY")}
                </span>
              </Col>
            </Row>
            <Row className="mt-2 align-items-center">
              <Col xs={18} className="">
                Address: <span className="ms-2">{billingDetails.address}</span>
              </Col>
              <Col xs={6} className="">
                Bus. Style:
              </Col>
            </Row>
            <Row className="mt-2 align-items-center">
              <Col xs={18} className="">
                TIN:
              </Col>
              <Col xs={6} className="">
                PO NO: <span className="ms-2">{billingDetails.po_no}</span>
              </Col>
            </Row>
            <Row className="mt-2 align-items-center"></Row>
            <Row className="my-3">
              <Col xs={24} className="">
                Remarks: <span className="ms-2">{billingDetails.remark}</span>
              </Col>
            </Row>
            <Row>
              <div className="app-container w-100 p-0 mb-5">
                <table tableBordered>
                  <thead>
                    <tr>
                      <th
                        className="custom-td custom-th text-center"
                        style={{ fontWeight: "bold", width: "10%" }}
                      >
                        Quantity
                      </th>
                      <th
                        className="custom-td custom-th text-center"
                        style={{ fontWeight: "bold", width: "55%" }}
                      >
                        Description
                      </th>
                      <th
                        className="custom-td custom-th text-end"
                        style={{ fontWeight: "bold", width: "15%" }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item) => (
                      <tr key={item.id}>
                        <td className="custom-td"></td>
                        <td className="custom-td text-center">
                          {item.description}
                        </td>
                        <td className="text-end custom-td">
                          {formatAmount(item.amount)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="custom-td pt-4"></td>
                      <td className="custom-td"></td>
                      <td className="custom-td"></td>
                    </tr>
                    <tr>
                      <td className="custom-td pt-4"></td>
                      <td className="custom-td"></td>
                      <td className="custom-td"></td>
                    </tr>
                    <tr>
                      <td className="custom-td pt-4"></td>
                      <td className="custom-td"></td>
                      <td className="custom-td"></td>
                    </tr>
                    <tr>
                      <td className="custom-td"></td>
                      <td className="text-end custom-td">
                        Total Sales (VAT Inclusive)
                      </td>
                      <td className="text-end custom-td">
                        {billingDetails.vat_type === "zero_rated" ||
                        billingDetails.vat_type === "vat_ex"
                          ? "0.00"
                          : formatAmount(billingDetails.vat_in)}
                      </td>
                    </tr>
                    <tr>
                      <td className="custom-td"></td>
                      <td className="text-end custom-td">Less: VAT</td>
                      <td className="text-end custom-td">
                        {billingDetails.vat_type === "zero_rated" ||
                        billingDetails.vat_type === "vat_ex"
                          ? "0.00"
                          : formatAmount(billingDetails.twelve_percent_vat)}
                      </td>
                    </tr>
                    <tr>
                      <td className="custom-td"></td>
                      <td className="custom-td">
                        <Row className="d-flex justify-content-between w-100">
                          <span>
                            VATable Sales:{" "}
                            {billingDetails.vat_type === "zero_rated" ||
                            billingDetails.vat_type === "vat_ex"
                              ? "0.00"
                              : formatAmount(
                                  parseFloat(billingDetails.net_vat).toFixed(2)
                                )}
                          </span>
                          <span>Amount: Net of VAT</span>
                        </Row>
                      </td>
                      <td className="text-end custom-td">
                        {billingDetails.vat_type === "zero_rated" ||
                        billingDetails.vat_type === "vat_ex"
                          ? "0.00"
                          : formatAmount(billingDetails.net_vat)}
                      </td>
                    </tr>
                    <tr>
                      <td className="custom-td"></td>
                      <td className="custom-td">
                        <Row className="d-flex justify-content-between w-100">
                          <span>
                            VAT Exempt Sales:{" "}
                            {formatAmount(
                              parseFloat(
                                billingDetails.vat_ex_sales || 0
                              ).toFixed(2)
                            )}
                          </span>
                          <span>Less: Withholding Tax</span>
                        </Row>
                      </td>
                      <td className="text-end custom-td">
                        {formatAmount(
                          parseFloat(billingDetails.wht_amount || 0).toFixed(2)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="custom-td"></td>
                      <td className="custom-td">
                        <Row className="d-flex justify-content-between w-100">
                          <span className="">
                            Zero-Rated Sales:{" "}
                            {billingDetails.vat_type === "zero_rated"
                              ? billingDetails.grand_total
                              : "0.00"}
                          </span>
                          <span>Amount Due</span>
                        </Row>
                      </td>
                      <td className="text-end custom-td">
                        {formatAmount(billingDetails.amount_due)}
                      </td>
                    </tr>
                    <tr>
                      <td className="custom-td"></td>
                      <td className="custom-td">
                        <Row className="d-flex justify-content-between w-100">
                          <span className="">
                            VAT Amount:{" "}
                            {billingDetails.vat_type === "zero_rated" ||
                            billingDetails.vat_type === "vat_ex"
                              ? "0.00"
                              : formatAmount(
                                  billingDetails.twelve_percent_vat
                                )}{" "}
                          </span>
                          <span>Add: VAT</span>
                        </Row>
                      </td>
                      <td className="text-end custom-td">
                        {billingDetails.vat_type === "zero_rated" ||
                        billingDetails.vat_type === "vat_ex"
                          ? "0.00"
                          : formatAmount(billingDetails.twelve_percent_vat)}
                      </td>
                    </tr>
                    <tr>
                      <td className="custom-td"></td>
                      <td className="text-end bold custom-td">
                        Total Amount Due
                      </td>
                      <td className="text-end bold custom-td">
                        {formatAmount(billingDetails.grand_total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Row>
            <Row className="d-flex jusitfy-content-between">
              <Col xs={16}>
                <Row>10 Bklts. (50x3) 0001-0500</Row>
                <Row>BIR Permit No. OCN: 043AU20240000007827</Row>
                <Row>Date of ATP: 06-21-2024</Row>
                <Row>R-BOS PRINTING PRESS</Row>
                <Row>NON VAT Reg. TIN: 115-677-573-00000</Row>
                <Row>163-B Bato-Bato St. Commonwealth, 1121 Quezon City,</Row>
                <Row>
                  NCR, Second District, Philippines Tel: 8681-4452/ CP #
                  0967-9658201
                </Row>
              </Col>
              <Col xs={8}>
                <Row className="underline-col">By:</Row>
                <Row className="d-flex justify-content-center">
                  Customer's Signature
                </Row>
                <Row className="d-flex justify-content-center smaller-text">
                  Printer's Accreditation No.: 028MP20190000000033
                </Row>
                <Row className="d-flex justify-content-center smaller-text">
                  Date Issued: July 12, 2019 Expiry Date: July 11, 2024
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className="d-flex justify-content-end my-4 pb-5 d-flex-responsive">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/billingsalesinvoice")}
          >
            Close
          </button>
          {billingDetails.payment_status === "open_bill" && isAdmin() ? (
            <button
              className="button-tertiary me-3"
              onClick={() => navigate("/billingsalesinvoice/edit/" + id)}
            >
              Edit
            </button>
          ) : (
            ""
          )}
          <button className="button-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </div>
  )
}
