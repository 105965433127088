import Moment from "react-moment"
import { getToken, getUser } from "../Utils/Common"
import { getAPICall, postAPICall, postAPICall2 } from "./axiosMethodCalls"
import { useContext } from "react"
import { Context } from "../Context/Context"

const user = getUser()
const token = getToken()

export const deleteProject = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/delete",
      {
        requester: getUser(),
        token: getToken(),
        project_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getAllProjects = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_all_project",
      {
        requester: user,
        token: token,
        business_unit_id: data.business_unit_id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getLastSubtypes = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "expense_types/get_all_last_expense_type",
      {
        requester: user,
        token: token,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getProject = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_project",
      {
        requester: user,
        token: token,
        project_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const searchProject = async (filter) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/search",
      {
        requester: getUser(),
        token: getToken(),
        ...filter,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const searchProjectInv = async (name) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/search",
      {
        requester: getUser(),
        token: getToken(),
        project_id: name,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getProjectTypes = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_project_type_names",
      {
        requester: getUser(),
        token: getToken(),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error }
  }
}

export const getAllProjectInv = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/search",
      {
        requester: getUser(),
        token: getToken(),
        ...data,
        date_from: data.from ? Moment(data.from).format("YYYY-MM-DD") : "",
        date_to: data.to ? Moment(data.to).format("YYYY-MM-DD") : "",
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

// GET SINGLE PURCHASE INVOICE
export const getSingleProjectPI = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/get_project_invoice",
      {
        requester: getUser(),
        token: getToken(),
        project_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const changeProjectPiStatus = async (ids, status) => {
  let form_data = new FormData()
  form_data.append("requester", getUser())
  form_data.append("token", getToken())
  form_data.append("status", status)

  ids.forEach((id) => {
    form_data.append("project_invoice_id[]", id)
  })

  try {
    const response = await postAPICall2(
      process.env.REACT_APP_LINK + "project_invoices/record_status_change",
      form_data
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
